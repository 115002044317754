// @ts-ignore
import moment from "moment";

export interface ICandidateNotify {
  firstName: string;
  lastName?: string;
  email: string,
  examCentreName?: string;
  examNumber?: string;
  examDate?: Date;
  certificateUid: string;
  syllabus: string;
  module: string;
}

export interface CandidateInfo {
    firstName: string,
    lastName?: string,
    birthDate: Date,
    passportNumber: string,

    email: string,
    special: boolean,
    createdAt?: string,
    createdBy?: string,
    _id?: string,
    examCenterId?: any,
    serialNumber?: string
}

class Candidate {
    firstName: string;
    lastName?: string;
    birthDate: Date;
    passportNumber: string;
    email: string;
    special: boolean;
    createdAt?: string;
    createdBy?: string;
    _id?: string;
    examCenterId?: any;
    serialNumber?: string

    constructor(candidateInfo: CandidateInfo) {
        this.firstName = candidateInfo.firstName;
        this.lastName = candidateInfo.lastName;
        this.birthDate = candidateInfo.birthDate;
        this.passportNumber = candidateInfo.passportNumber;
        this.email = candidateInfo.email;
        this.special = candidateInfo.special;
        this.createdAt = candidateInfo.createdAt;
        this.createdBy = candidateInfo.createdBy;
        this._id = candidateInfo._id;
        this.examCenterId = candidateInfo.examCenterId;
        this.serialNumber = candidateInfo.serialNumber;
    }

    fullName(): string {
        return this.lastName ? this.firstName + " " + this.lastName : this.firstName;
    }

    load(data: any): Candidate {
        return new Candidate(data);
    }


    toObject(): any {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            birthDate: this.birthDate,
            passportNumber: this.passportNumber,
            email: this.email,
            special: this.special,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            _id: this._id,
            examCenterId: this.examCenterId,
            serialNumber: this.serialNumber
        }
    }

    emptyCandidate(): Candidate {
        return new Candidate({
            _id: undefined,
            firstName: '',
            lastName: '',
            birthDate: new Date(),
            passportNumber: '',
            email: '',
            special: false
        });
    }

    checkDuplicate(candidate: Candidate, vals: any): boolean {
        return candidate.firstName == vals?.firstName &&
            candidate.lastName == vals?.lastName &&
            moment(candidate.birthDate).format("YYYY-MM-DD") == moment(vals?.birthDate).format("YYYY-MM-DD");
    }


}

export default Candidate;
