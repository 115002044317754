import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { AppState } from 'store/store';
import UserApi from 'api/UserApi';
import ApiCaller from 'lib/ApiCaller';
import PassRateReport from '../../components/dashboard/exams/PassRateReport';
import ExamCandidatesReport from '../../components/dashboard/exams/ExamCandidatesReport';

import ActiveQuestionsPercentageReport from '../../components/dashboard/questions/ActiveQuestionsPercentageReport';
import QuestionsPerModuleAndTopicReport from '../../components/dashboard/questions/QuestionsPerModuleAndTopicReport';
import AvgMarkPerMarkerReport from 'views/components/dashboard/marks/AvgMarkPerMarkerReport';
import QuestionsPipelineReport from '../../components/dashboard/questions/QuestionsPipelineReport';
import { DEFAULT_DASHBOARD_STATS } from '../../../shared/constants/defaultDashboardStats';
import StatsCardsContainer from '../../../components/StatsCardsContainer/StatsCardsContainer';

import 'react-toastify/dist/ReactToastify.css';
import QuestionBankReport from 'views/components/dashboard/questions/QuestionBankReport';
import ExamCentresMarksReport from 'views/components/dashboard/marks/ExamCentresMarksReport';
import ExamAnalytics from '../admin/exam_analytics/ExamAnalytics';
import { useSyllabus } from 'hooks';

const ExamAdminDashboard: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [data, setData] = useState(DEFAULT_DASHBOARD_STATS);
  const { allowedSyllabuses } = useSyllabus();

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      const userApi = new UserApi(new ApiCaller());
      userApi
        .getMyStats(loggedUser.token)
        .then((data: any) => {
          if (data) {
            setData(data);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error retrieving data?.');
        });
    }
  }, [loggedUser]);

  return (
    <>
      <div className="content">
        <Row>
          <StatsCardsContainer data={data.numerical} />
        </Row>
        <Row>
          <Col>
            <QuestionsPipelineReport {...{ allowedSyllabuses }} />
          </Col>
          <Col>
            <ActiveQuestionsPercentageReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <QuestionsPerModuleAndTopicReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <QuestionBankReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PassRateReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamCandidatesReport />
          </Col>
        </Row>
        <Row>
          <Col>
            <AvgMarkPerMarkerReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamCentresMarksReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamAnalytics />
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default ExamAdminDashboard;
