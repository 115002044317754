import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row,
} from 'reactstrap';

import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';
import ReactTable from 'components/ReactTable/ReactTable';
import { AppState } from 'store/store';

import NavigationExam from 'lib/common/models/navigationExam';
import NavigationExamCollection from 'lib/common/models/navigationExamCollection';
import Syllabus from 'lib/common/models/syllabus';
import ApiCaller from 'lib/ApiCaller';
import {
  NavigationExamStatus,
  ScriptIntentions,
} from 'lib/common/models/navigationExam';
import { downloadExamSchedules } from 'shared/utils/ApiCommands';
import NavigationExamListPresenter from './navigationExamListPresenter';

import NavigationExamApi from 'api/NavigationExamApi';

import { setCurrentDoc, setCurrentExam } from 'store/actions/docs/docsActions';

import {
  useClearListStateOnPageChange,
  useSyllabus,
  useTableControls,
} from 'hooks';
import { DEFAULT_TIMEZONE_FORMAT, formatDateTime } from 'shared/utils/dateTime';
import { onTableExport } from 'shared/utils/excel';
import { updateListSyllabus } from 'store/actions/lists/listsActions';
import { stateListNames } from 'store/reducers/listsReducer';

import 'react-toastify/dist/ReactToastify.css';

const NavigationExamList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const syllabus = useSelector(
    (state: AppState) => state.lists.examList.syllabus,
  );
  const [exams, updateExams] = React.useState<NavigationExamCollection>(
    new NavigationExamCollection([]),
  );

  const listName = stateListNames.examList;
  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    '/navigation_exam/list',
    '/navigation_exam/detail',
    '/navigation_exam/request',
  ]);

  const navigationExamApi = new NavigationExamApi(
    new ApiCaller(loggedUser.token),
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<boolean>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [exam, setExam] = useState<any>();
  const [selectedSyllabus, setSelectedSyllabus] = useState<
    Syllabus | undefined
  >(syllabus);
  const [selectedExams, updateSelectedExams] =
    useState<NavigationExamCollection>(new NavigationExamCollection([]));
  const { syllabus: syllabuses } = useSyllabus();
  const [loading, setLoading] = useState<boolean>(false);

  const examsForExportRef = useRef(new NavigationExamCollection([]));

  const view = { history: history, layout: layout, setStatus: setStatus };

  const onGenerateCallback = useCallback((exam: any) => {
    setIsConfirmationModalOpen(true);
    setExam(exam);
  }, []);

  const generateExam = useCallback(
    (exam) => {
      setLoading(true);
      navigationExamApi
        .generateBasicDraftExam(exam.id)
        .then((msg: any) => {
          dispatch(setCurrentDoc(msg.doc));
          dispatch(setCurrentExam(msg.exam));
          setLoading(false);
          view.history.push(`/${view.layout}/navigation_exam/edit`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    },
    [view, navigationExamApi, dispatch],
  );

  const presenter = new NavigationExamListPresenter(
    loggedUser,
    view,
    onGenerateCallback,
  );

  const closeConfirmationModal = useCallback(
    () => setIsConfirmationModalOpen(false),
    [],
  );

  const filterExams = useCallback(
    (
      exams: NavigationExamCollection,
      selectedSyllabus: Syllabus,
    ): NavigationExam[] => {
      return exams.navigationExams.filter(
        (q: any) => q.syllabus?._id === selectedSyllabus?._id,
      );
    },
    [],
  );

  const onSyllabusChange = (selectedSyllabus: any) => {
    setSelectedSyllabus(selectedSyllabus);
    dispatch(updateListSyllabus(selectedSyllabus, listName));
    const updated = filterExams(exams, selectedSyllabus);
    updateSelectedExams(new NavigationExamCollection(updated));
  };

  const onChangeRows = useCallback((rows: any) => {
    examsForExportRef.current = new NavigationExamCollection(
      rows.map((row: any) => row.original),
    );
  }, []);

  useEffect(() => {
    presenter.getAllExams().then((exams) => {
      updateExams(exams);
      if (selectedSyllabus) {
        const filteredExams = filterExams(exams, selectedSyllabus);
        updateSelectedExams(new NavigationExamCollection(filteredExams));
      } else {
        updateSelectedExams(exams);
      }
    });
  }, [status, selectedSyllabus, filterExams]);

  const exportExamsList = () => {
    const columns = presenter.getTableColumns();
    const exportData = examsForExportRef.current.navigationExams.map(
      (exam: NavigationExam) => ({
        examNumber: exam.getExamNumber() ?? '-',
        examCentre: exam.examCenterId
          ? `(${exam.examCenterId.code}) ${exam.examCenterId.name}`
          : '',
        module: exam.module ? `${exam.module.code} - ${exam.module.title}` : '',
        date: formatDateTime(exam.date, DEFAULT_TIMEZONE_FORMAT),
        scriptIntention: exam.scriptIntention
          ? ScriptIntentions[exam.scriptIntention]
          : '',
        status: exam.status ? NavigationExamStatus[exam.status] : '',
      }),
    );
    onTableExport(
      `Exams List ${new Date().toDateString()}`,
      columns.slice(0, -1),
      exportData,
    );
  };

  return (
    <>
      <div className="content">
        <LoadingSpinner spinning={loading} tip={'Generating exam...'}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{presenter.getTableName()}</CardTitle>
                </CardHeader>
                {loggedUser.hasAdminRole() && (
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="syllabus"
                        isClearable={true}
                        onChange={onSyllabusChange}
                        options={syllabuses}
                        getOptionLabel={(item) => item.code + ' ' + item.title}
                        getOptionValue={(item) => item.code}
                        placeholder="Choose a syllabus"
                        value={selectedSyllabus}
                      />
                    </FormGroup>
                  </Col>
                )}
                <CardBody>
                  <Row>
                    <Col>
                      {loggedUser.hasExamRequestPermission() && (
                        <Link
                          to={`/${layout}/navigation_exam/request`}
                          className="btn-label">
                          <Button color="info">
                            <span>
                              <i className="nc-icon nc-simple-add" />
                            </span>
                            Request
                          </Button>
                        </Link>
                      )}
                      <Button
                        onClick={() => {
                          downloadExamSchedules(loggedUser.token);
                        }}>
                        <span>
                          <i className="nc-icon nc-cloud-download-93" />
                        </span>
                        Exam Dates (PDF)
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button
                        onClick={() => exportExamsList()}
                        color="success"
                        className="btn-label">
                        <span>
                          <i className="nc-icon nc-cloud-download-93" />
                        </span>
                        Export to Excel
                      </Button>
                    </Col>
                  </Row>
                  <ReactTable
                    data={selectedExams?.navigationExams}
                    columns={presenter.getTableColumns()}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    onChangeRows={onChangeRows}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
          {isConfirmationModalOpen && (
            <ConfirmationModal
              title="Generate exam confirmation"
              onConfirm={() => generateExam(exam)}
              onCancel={closeConfirmationModal}
              text="Are you sure you want to generate this exam?"
            />
          )}
        </LoadingSpinner>
      </div>
    </>
  );
};

export default NavigationExamList;
