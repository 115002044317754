import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import { AppState } from '../../../../store/store';
import Settings from '../../../../lib/settings';
import UserCollection from '../../../../lib/common/models/userCollection';
import UserApi from '../../../../api/UserApi';
import ApiCaller from '../../../../lib/ApiCaller';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import User, { UserTypes } from '../../../../lib/common/models/user';
import Notifications from 'lib/notifications';
import {
  updateAvatar,
  updateSession,
} from 'store/actions/session/sessionActions';
import { updateNotifications } from 'store/notificationStore';
import { SessionState } from 'store/reducers/sessionReducer';
import { getJwtExpiration } from 'shared/utils/jwt';

import 'react-toastify/dist/ReactToastify.css';
import AuthApi from 'api/AuthApi';
import { userTypesRedirect } from 'layouts/routes';

const UserList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [users, updateUsers] = React.useState<UserCollection>(
    new UserCollection([]),
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const userApi = new UserApi(new ApiCaller());
    userApi.getAllUsers(loggedUser.token).then((users: any) => {
      if (users) {
        updateUsers(users);
      }
    });
  }, []);

  const chooseUser = (cell: any) => {
    const user = cell.row.original;
    Settings.setCurrentAdminUser(user);
    history.push(`/${layout}/user/detail`);
  };

  const deleteUser = (cell: any) => {
    const user = cell.row.original;

    const userApi = new UserApi(new ApiCaller());
    userApi
      .deleteUser(user._id || '', loggedUser.token)
      .then(() => {
        toast.success('User deleted');
        const updatedCollection = new UserCollection(
          users.users.filter((item: unknown) => item !== user),
        );
        updateUsers(updatedCollection);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error deleting user');
      });
  };

  const loginAsUser = (user: User) => {
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .loginAs(loggedUser.token, user.email)
      .then((data: any) => {
        if (data.error) {
          toast.error('Failed to login');
          return;
        }
        const user = new User({
          _id: data._id,
          name: data.name,
          email: data.email,
          token: data.token,
          type: data.type,
          status: data.status,
          entityId: data.entityId,
          impersonated: data.impersonated,
        });

        const sessionData: SessionState = {
          loggedIn: user.email ? true : false,
          ready: true,
          userInfo: user,
          tokenExpiration: getJwtExpiration(user.token),
          type: user.type,
          layout: user.type === 'admin' ? 'navozyme' : user.type,
        };
        dispatch(updateSession(sessionData));

        Settings.setCurrentUser(user).then(() => {
          const userApi = new UserApi(new ApiCaller());
          const url = userApi.getAvatarUrl(user._id || '');
          dispatch(updateAvatar(url));
          dispatch(
            updateNotifications({
              options: Notifications.restoreNotifications(user._id),
            }),
          );

          if (sessionData.userInfo.type === 'admin') {
            history.push('/navozyme/dashboard');
          } else {
            const userType: string = sessionData.userInfo.type.toLowerCase();
            history.push(`/${userType}${userTypesRedirect[userType]}`);
          }
          window.location.reload();
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Failed to login');
      });
  };

  const columns = [
    { Header: 'NAME', accessor: 'name', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'TYPE',
      accessor: (d: any) => (d.type ? UserTypes[d.type] : ''),
      sortable: true,
    },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) => (d.entityId ? d.entityId?.name : '-'),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item
            disabled={!loggedUser.isAdmin()}
            onClick={() => chooseUser(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;VIEW
          </Dropdown.Item>
          {loggedUser.isAdmin() && (
            <Dropdown.Item onClick={() => loginAsUser(row.row.original)}>
              <i className="nc-icon nc-single-02" />
              &nbsp;&nbsp;LOGIN AS
            </Dropdown.Item>
          )}
          {loggedUser.isAdmin() && (
            <Dropdown.Item onClick={() => deleteUser(row)}>
              <i className="nc-icon nc-simple-delete" />
              &nbsp;&nbsp;DELETE
            </Dropdown.Item>
          )}
        </DropdownButton>
      ),
    },
  ];

  const renderCreateUserButton = (isUserHaveAccess: boolean) => {
    if (isUserHaveAccess) {
      return (
        <Link to={`/${layout}/user/create`} className="btn-label">
          <Button color="info">
            <span>
              <i className="nc-icon nc-simple-add" />
            </span>
            Create
          </Button>
        </Link>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Users List</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {renderCreateUserButton(
                      loggedUser.hasCreateUserPermissions(),
                    )}
                  </Col>
                </Row>
                <ReactTable data={users.users} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default UserList;
