import NavigationExamApi from 'api/NavigationExamApi';
import ApiCaller from 'lib/ApiCaller';
import NavigationExamListTable, {
  ListTable,
  ListTableView,
} from './navigationExamListTable';

class NavigationExamListPresenter {
  loggedUser: any;
  navigationExamApi: NavigationExamApi;
  table: ListTable;

  constructor(
    profile: any,
    private view: ListTableView,
    onGenerateCallback: (exam: any) => void,
  ) {
    this.loggedUser = profile;
    this.table = new NavigationExamListTable(
      this.loggedUser,
      view,
      onGenerateCallback,
    );
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(this.loggedUser.token),
    );
  }

  async getAllExams(): Promise<any> {
    return await this.navigationExamApi.getAllActiveExams();
  }

  getTableColumns() {
    return this.table.getColumns();
  }

  getTableName(): string {
    return this.table.getName();
  }
}

export default NavigationExamListPresenter;
