import React, { useCallback, useState } from 'react';
import ReactDatetime from 'react-datetime';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';

import Settings from 'lib/settings';
import { AppState } from 'store/store';

import ApiCaller from 'lib/ApiCaller';
import UserApi from 'api/UserApi';
import NavigationExam, {
  ScriptIntentions,
} from 'lib/common/models/navigationExam';
import { PaymentTypeLabels } from 'lib/common/models/transaction';
import { UserTypesLabels } from 'lib/common/models/user';
import QuestionApi from 'api/QuestionApi';
import NavigationExamApi from 'api/NavigationExamApi';
import {
  downloadCertificate,
  downloadPreviewCertificate,
} from 'shared/utils/ApiCommands';
import CertificateApi from 'api/CertificateApi';
import ExamGrade from 'lib/common/models/examGrade';
import ExamGradeCollection from 'lib/common/models/examGradeCollection';
import DetailLayout from 'layouts/DetailLayout';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import NavigationExamPayComponent from 'views/components/payment/navigationExamPayComponent';
import CandidateResults from '../../../../components/candidateResults/CandidateResults';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';
import { useSyllabus } from 'hooks';

const NavigationExamResults: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [currentCell, setCurrentCell] = useState<any>({});
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [firstMarkers, setFirstMarkers] = useState<any[]>([]);
  const [secondMarkers, setSecondMarkers] = useState<any[]>([]);
  const [examGrades, setExamGrades] = React.useState<ExamGradeCollection>(
    new ExamGradeCollection([]),
  );

  const [modules, setModules] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTip, setLoadingTip] = useState<string>('');
  const { syllabus } = useSyllabus();

  React.useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);

    const userApi = new UserApi(new ApiCaller(loggedUser.token));
    userApi.getUsersByType(UserTypesLabels.mcaMarker).then((userArr: any) => {
      setMarkers(userArr.users);
      setFirstMarkers(userArr.users);
      setSecondMarkers(userArr.users);
    });

    if (exam.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getCandidatesResults(exam?.id).then((data: any) => {
        setExamGrades(data);
      });
    }
  }, []);

  const updateGrades = () => {
    if (currentExam?.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getCandidatesResults(currentExam.id).then((data: any) => {
        setExamGrades(data);
      });
    }
  };

  React.useEffect(() => {
    if (currentExam?.syllabus) {
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi
        .getModulesFromSyllabus(currentExam.syllabus._id)
        .then((m: any) => {
          setModules(m.modules);
        });
    }
  }, [currentExam]);

  const generate = useCallback(() => {
    setLoading(true);
    const selectedGrade: ExamGrade = currentCell.row.original;
    const certificateApi = new CertificateApi(new ApiCaller());
    certificateApi
      .generateCertificate(selectedGrade._id!, loggedUser.token)
      .then((certificate) => {
        const updatedGrade = selectedGrade;
        updatedGrade.certificateId = certificate._id;
        const updatedGrades = examGrades.grades.map((item) => {
          return item._id == selectedGrade._id ? updatedGrade : item;
        });
        setExamGrades(new ExamGradeCollection(updatedGrades));
        setLoading(false);
        setIsCertificateModalOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        setIsCertificateModalOpen(false);
        toast.error('Failed to generate certificate');
      });
  }, [currentCell]);

  const reissueCertificate = useCallback(() => {
    setLoading(true);
    const selectedGrade: ExamGrade = currentCell.row.original;
    const certificateApi = new CertificateApi(new ApiCaller());
    certificateApi
      .reissue(selectedGrade.certificateId!, loggedUser.token)
      .then((certificate) => {
        const updatedGrade = selectedGrade;
        updatedGrade.certificateId = certificate._id;
        const updatedGrades = examGrades.grades.map((item) => {
          return item._id == selectedGrade._id ? updatedGrade : item;
        });
        setExamGrades(new ExamGradeCollection(updatedGrades));
        setModalPayment(false);
        setLoading(false);
        toast.success('Certificate has been reissued');
      })
      .catch((error) => {
        setModalPayment(false);
        setLoading(false);
        toast.error(
          'Failed to reissue certificate. Payment has been reverted.',
        );
      });
  }, [currentCell, loggedUser.token]);

  const displayResult = (
    result: string,
    grade?: number,
    details?: any,
  ): string => {
    if (result == 'MARGINAL') {
      result = 'BORDERLINE';
      grade = undefined;
    }
    let examGrade = grade ? `<b>${result}(${grade})</b>` : result;
    if (details) {
      examGrade = examGrade + ' / ';
      for (let key in details) {
        examGrade =
          examGrade + `${key}: ${details[key].result}(${details[key].grade}), `;
      }
      examGrade = examGrade.substring(0, examGrade.length - 2);
    }
    return examGrade;
  };

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) =>
        d.candidateId.serialNumber ? d.candidateId.serialNumber : '-',
      sortable: true,
    },
    {
      Header: 'NAME',
      accessor: (d: any) =>
        `${d.candidateId.firstName} ${d.candidateId.lastName}`,
      sortable: false,
    },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) =>
        moment(d.candidateId.birthDate).utc().format('DD-MM-YYYY'),
    },
    {
      Header: 'RESULT',
      Cell: (cell: any) => (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: displayResult(
                cell.row.original.result,
                cell.row.original.grade,
                cell.row.original.details,
              ),
            }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (cell: any) => (
        <div>
          {!cell.row.original.certificateId && (
            <>
              <Button
                className="btn-round"
                color="success"
                title="Generate Certificate"
                onClick={() => {
                  setCurrentCell(cell);
                  setIsCertificateModalOpen(true);
                }}
                disabled={
                  !cell.row.original.isCertificateEligible() ||
                  loggedUser.isExamCentreAuditor() ||
                  loading
                }>
                Certificate
              </Button>
              <Button
                className="btn-round"
                color="info"
                onClick={() => {
                  downloadPreviewCertificate(
                    cell.row.original._id,
                    loggedUser.token,
                  );
                }}
                disabled={!cell.row.original.isCertificateEligible()}>
                Preview
              </Button>
            </>
          )}
          {cell.row.original.certificateId && (
            <>
              <Button
                className="btn-round"
                color="success"
                title="Download Certificate"
                onClick={() => {
                  downloadCertificate(
                    cell.row.original.certificateId,
                    loggedUser.token,
                  );
                }}>
                <i className="nc-icon nc-cloud-download-93" />
                Download
              </Button>
              <Button
                className="btn-round"
                color="info"
                onClick={() => {
                  setCurrentCell(cell);
                  setModalPayment(true);
                }}>
                Reissue certificate
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <LoadingSpinner spinning={loading} tip={loadingTip}>
          <DetailLayout title={'Go back to Results Declared'}>
            {currentExam && (
              <>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Exam Results</h4>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-horizontal">
                      <Row>
                        <Label sm="2">Exam Centre</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              readOnly
                              value={currentExam.examCenterId?.name}
                              type="text"
                              name="Exam Centre"
                              placeholder="Exam Centre"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Syllabus</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="syllabus"
                              value={currentExam?.syllabus}
                              options={syllabus}
                              getOptionLabel={(item) =>
                                item.code + ' ' + item.title
                              }
                              getOptionValue={(item) => item.code}
                              placeholder="Choose a syllabus"
                              isDisabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Module</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="module"
                              value={currentExam?.module}
                              options={modules}
                              getOptionLabel={(item) =>
                                item.code + ' ' + item.title
                              }
                              getOptionValue={(item) => item.code}
                              placeholder="Choose a module"
                              isDisabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Date & Time</Label>
                        <Col sm="10">
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{ readOnly: !loggedUser.isAdmin() }}
                              timeFormat={'HH:mm (Z)'}
                              dateFormat={'DD-MM-YYYY'}
                              value={moment(currentExam?.date)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Estimated Candidates</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={currentExam.numCandidates}
                              type="number"
                              name="numCandidates"
                              placeholder="Estimated Candidates"
                              required
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Script Intention</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="status"
                              value={{
                                label:
                                  ScriptIntentions[
                                    currentExam?.scriptIntention
                                  ],
                                value: currentExam?.scriptIntention,
                              }}
                              options={Object.keys(ScriptIntentions).map(
                                function (type) {
                                  return {
                                    value: type,
                                    label: ScriptIntentions[type],
                                  };
                                },
                              )}
                              placeholder="Status"
                              isDisabled={!loggedUser.isAdmin()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {currentExam?.isCancelled() && (
                        <Row>
                          <Label sm="2">Cancellation date</Label>
                          <Col sm="10">
                            <FormGroup>
                              <ReactDatetime
                                inputProps={{ readOnly: true }}
                                timeFormat={'HH:mm'}
                                dateFormat={'DD-MM-YYYY'}
                                value={moment(currentExam?.cancellationDate)}
                              />
                            </FormGroup>
                            {currentExam?.isLateCancellation() && (
                              <FormGroup className={`has-label has-danger`}>
                                <label className="error">
                                  This exam was a late cancellation.
                                </label>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      )}
                      {loggedUser.hasAdminRole() && (
                        <Row>
                          <Label sm="2">First Marker</Label>
                          <Col sm="10">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="firstMarker"
                                value={currentExam?.firstMarker}
                                options={firstMarkers}
                                getOptionLabel={(item) =>
                                  `${item.name} (${item.email})`
                                }
                                getOptionValue={(item) =>
                                  `${item.name} (${item.email})`
                                }
                                placeholder="Choose first marker"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {loggedUser.hasAdminRole() && (
                        <Row>
                          <Label sm="2">Second Marker</Label>
                          <Col sm="10">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="secondMarker"
                                value={currentExam?.secondMarker}
                                options={secondMarkers}
                                getOptionLabel={(item) =>
                                  `${item.name} (${item.email})`
                                }
                                getOptionValue={(item) =>
                                  `${item.name} (${item.email})`
                                }
                                placeholder="Choose second marker"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
                <Card>
                  <CandidateResults
                    currentExam={currentExam}
                    setLoading={setLoading}
                    setLoadingTip={setLoadingTip}
                    loading={loading}
                  />
                </Card>
              </>
            )}
          </DetailLayout>
          <ToastContainer />
        </LoadingSpinner>
        {isCertificateModalOpen && (
          <ConfirmationModal
            title="Certificate confirmation"
            onConfirm={() => generate()}
            onCancel={() => setIsCertificateModalOpen(false)}
            text="Are you sure you want to generate this certificate? Make sure the candidate data is correct before proceeding."
            processingAction={loading}
          />
        )}
        <Modal isOpen={modalPayment}>
          <ModalHeader>Certificate Reissue Fee</ModalHeader>
          <ModalBody>
            <NavigationExamPayComponent
              onCancel={() => setModalPayment(false)}
              onSuccess={() => reissueCertificate()}
              paymentType={PaymentTypeLabels.CERTIFICATE_REISSUE_FEE}
              reference={''}
              certificateId={currentCell.row?.original?.certificateId!}
              disableConfirm={loading}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default NavigationExamResults;
