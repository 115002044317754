import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

//@ts-ignore
import { Button as AntdButton } from 'antd';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';

import DetailLayout from 'layouts/DetailLayout';
import { setCurrentDoc, setCurrentExam } from 'store/actions/docs/docsActions';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';
import ExamDocApi from '../../../api/ExamDocApi';
import ExamResourceApi from '../../../api/ExamResourceApi';
import NavigationExamApi from '../../../api/NavigationExamApi';
import QuestionApi from '../../../api/QuestionApi';
import ApiCaller from '../../../lib/ApiCaller';
import Exam, { ExamContentElement } from '../../../lib/common/models/exam';
import ExamResource from '../../../lib/common/models/examResource';
import ExamResourceCollection from '../../../lib/common/models/examResourceCollection';
import MYDocument from '../../../lib/common/models/myDocument';
import Settings from '../../../lib/settings';
import {
  downloadAnswers,
  downloadDocument,
  sendToWritePDF,
} from '../../../shared/utils/ApiCommands';
import { getBase64Content } from '../../../shared/utils/Base64Content';
import { AppState } from '../../../store/store';

import './adhoc-styles.css';

import TinyMCETextEditor from '../../../components/TinyMCETextEditor';
import { getRegex } from './helper';

type MyProps = RouteComponentProps<{
  slug: string;
}>;

const ExamEditor: FC<MyProps> = () => {
  const [customName, scustomName] = useState('');
  const [customDescription, scustomDescription] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTip, setLoadingTip] = useState('');
  const [shuffleConfirm, setShuffleConfirm] = useState(false);
  const [resourceModal, setResourceModal] = useState(false);
  const [resourcesToAdd, setResourcesToAdd] = useState<ExamResource[]>([]);
  const [resources, setResources] = useState<ExamResource[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);

  const currentDoc: any = useSelector((state: AppState) => state.current.doc);
  const currentExam: any = useSelector((state: AppState) => state.current.exam);

  const currentUser = useSelector((state: AppState) => state.session.userInfo);
  const editorContRef: any = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const examResourceApi = new ExamResourceApi(
      new ApiCaller(currentUser.token),
    );
    examResourceApi.getAll().then((data: ExamResourceCollection) => {
      if (currentExam.metadata?.relatedDocuments) {
        const currentExamResources = currentExam.metadata?.relatedDocuments.map(
          (item: any) => item._id,
        );
        setResources(
          data.resources.filter(
            (resource: ExamResource) =>
              !currentExamResources.includes(resource.id),
          ),
        );
      } else {
        setResources(data.resources);
      }
    });
  }, [currentExam]);

  const b64 = (newContent: string | null = null) => {
    if (newContent) {
      return getBase64Content(newContent);
    }
    return getBase64Content(currentDoc.content);
  };

  useEffect(() => {
    const navigationExamApi = new NavigationExamApi(
      new ApiCaller(currentUser.token),
    );
    scustomName(currentDoc.customName);
    scustomDescription(currentDoc.customDescription);
    navigationExamApi
      .getDraftById(currentDoc.id)
      .then((item) => {
        dispatch(setCurrentDoc(new MYDocument(item)));
      })
      .catch((err) => {
        console.error(err);
      });

    navigationExamApi
      .getExamModelBySlug(currentDoc.sourceSlug)
      .then((exam) => {
        dispatch(setCurrentExam(exam));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [examDoc, setExamDoc] = useState<any>('');

  useEffect(() => {
    if (currentDoc.content !== undefined) {
      setExamDoc(currentDoc);
    }
  }, [currentDoc]);

  const getEditor = (currentEditor: any) => {
    editorContRef.current = currentEditor;
  };

  /**
   * Shuffle exams
   */
  const shuffleDraft = () => {
    setLoadingTip('Shuffling exams...');
    setLoading(true);
    const navigationExamApi = new NavigationExamApi(new ApiCaller());
    navigationExamApi
      .shuffleExam(currentDoc.id, currentExam.id, currentUser.token)
      .then((res) => {
        dispatch(setCurrentDoc(new MYDocument(res.doc)));
        dispatch(setCurrentExam(new Exam(res.exam)));
        editorContRef.current.setContent(new MYDocument(res.doc).content);
        setShuffleConfirm(false);
        setLoading(false);
        toast.success('Exam shuffled');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const saveDraft = (
    newDraftId?: string | null,
    newContent?: string | null,
  ) => {
    if (currentUser.type == 'guest') {
      return;
    }
    const examDocApi = new ExamDocApi(new ApiCaller());
    const userId = currentUser._id ? currentUser._id : 'FAKEUSER';
    let draftId = currentDoc.id ? currentDoc.id : 'ERROR';
    if (newDraftId) {
      draftId = newDraftId;
    }
    const vars: any[] = currentDoc.vars;
    const b64content = b64(newContent);
    examDocApi
      .updateDraft(
        b64content,
        currentDoc.sourceSlug,
        userId,
        customName,
        customDescription,
        currentDoc.expirationDate,
        vars,
        draftId,
      )
      .then((doc) => {
        toast.success('Draft updated successfully');
        dispatch(setCurrentDoc(new MYDocument(doc)));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateDoc = (e: any) => {
    currentDoc.content = e;
  };

  const questionListIsEmpty = () => {
    return currentExam.examContent.content[0]._id != 'nothing';
  };

  // TODO: CTA on questions
  const onQuestionClick = useCallback(
    (event: any, question: ExamContentElement) => {
      {/* 2024-06-24: Button with class 'remove-question-button' is hidden
        because there is no way to restore a question if it was removed accidentally */}
      if (event.target.className.includes('remove-question-button')) {
        // Delete question
        const examApi = new NavigationExamApi(new ApiCaller());
        console.log('Remove question; ' + question.questionName);
        const questionId = question.questionId;
        setLoadingTip('Removing question...');
        setLoading(true);
        examApi
          .removeExamQuestion(
            currentDoc.id,
            currentExam.id,
            question.questionName,
            currentUser.token,
          )
          .then((res) => {
            setLoading(false);
            let myDocument = new MYDocument(res.doc);
            const regex = getRegex(questionId);

            const existQuestion = currentDoc.content.match(regex);
            if (existQuestion) {
              myDocument.content = currentDoc.content?.replace(regex, '');
            }
            dispatch(setCurrentDoc(myDocument));
            let myExam: any = new Exam(res.exam);
            myExam = {
              ...myExam,
              metadata: currentExam.metadata,
            };
            if (currentExam.metadata.relatedDocuments.length) {
              const resources = currentExam.metadata.relatedDocuments.map(
                (relDoc: any) => ({ ...relDoc, id: relDoc._id }),
              );
              examApi
                .addExamResources(currentExam.id, resources, currentUser.token)
                .then(() => {
                  console.info('Resources was added');
                });
            }
            dispatch(setCurrentExam(myExam));
            editorContRef.current.setContent(myDocument.content);
            toast.success('Question removed.');
            saveDraft(myDocument?.id, myDocument.content);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      } else if (event.target.className.includes('shuffle-question-button')) {
        // Shuffle/Update question
        console.log('Shuffle question; ' + question.questionName);
        const examApi = new NavigationExamApi(new ApiCaller());
        setLoadingTip('Shuffling question...');
        setLoading(true);
        const questionId = question.questionId;
        examApi
          .shuffleExamQuestion(
            currentDoc.id,
            currentExam.id,
            questionId,
            question.topicId,
            currentUser.token,
          )
          .then((res) => {
            setLoading(false);
            let myDocument = new MYDocument(res.doc);
            const examContent = res.exam.examContent.content;
            const currentQuestionIDs = currentExam.examContent.content.map(
              (ce: any) => ce.questionId,
            );
            const newQuestionIDs = examContent.map(
              (content: any) => content.questionId,
            );
            const newQuestionId = newQuestionIDs.find(
              (id: string) => !currentQuestionIDs.includes(id),
            );
            if (newQuestionId) {
              const newQuestion = examContent.find(
                (ec: any) => ec.questionId === newQuestionId,
              );
              const regex = getRegex(questionId);

              const existQuestion = currentDoc.content.match(regex);
              if (existQuestion && newQuestion) {
                myDocument.content = currentDoc.content?.replace(
                  regex,
                  `<div id="${newQuestionId}">${newQuestion.html}</div>`,
                );
              }
            } else {
              myDocument.content = currentDoc.content;
            }
            let myExam: any = new Exam(res.exam);

            if (currentExam.metadata.relatedDocuments.length) {
              const resources = currentExam.metadata.relatedDocuments.map(
                (relDoc: any) => ({ ...relDoc, id: relDoc._id }),
              );
              examApi
                .addExamResources(currentExam.id, resources, currentUser.token)
                .then(() => {
                  console.info('Resources was added');
                });
            }
            myExam = {
              ...myExam,
              metadata: currentExam.metadata,
            };
            dispatch(setCurrentExam(myExam));
            dispatch(setCurrentDoc(myDocument));

            editorContRef.current.setContent(myDocument.content);
            toast.success('Question updated.');
            saveDraft(myDocument?.id, myDocument.content);
          })
          .catch((err) => {
            setLoading(false);
            toast.error('Unable to shuffle question.');
            console.error(err);
          });
      } else {
        // Get One Question
        const questionApi = new QuestionApi(new ApiCaller(currentUser.token));
        questionApi
          .getQuestionById(question.questionId)
          .then((question) => {
            Settings.setCurrentAdminQuestion(question);
            const layout =
              currentUser.type === 'admin'
                ? 'navozyme'
                : currentUser.type.toLowerCase();
            history.push(`/${layout}/question/detail`);
          })
          .catch((err) => {
            toast.error('Unable to open question details.');
            console.log(err);
          });
      }
    },
    [history, currentUser, currentExam, currentDoc],
  );

  const removeExamResourceFromDraft = useCallback(
    (resource) => {
      const examApi = new NavigationExamApi(new ApiCaller());
      setLoadingTip('Removing resource...');
      setLoading(true);
      examApi
        .removeExamResource(currentExam.id, resource._id, currentUser.token)
        .then((data) => {
          setLoading(false);
          dispatch(setCurrentExam(data));
          toast.success('Resource removed.');
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    },
    [currentExam, currentUser],
  );

  const closeResourceModal = () => {
    setResourcesToAdd([]);
    setResourceModal(false);
  };

  const onResourceChange = (e: any) => {
    console.log(e);
    setResourcesToAdd(e);
  };

  const addResources = useCallback(() => {
    const examApi = new NavigationExamApi(new ApiCaller());
    setLoadingTip('Adding resources...');
    setResourceModal(false);
    setLoading(true);
    examApi
      .addExamResources(currentExam.id, resourcesToAdd, currentUser.token)
      .then((data) => {
        setLoading(false);
        setResourcesToAdd([]);
        dispatch(setCurrentExam(data));
        toast.success('Resources added.');
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [currentExam, currentUser, resourcesToAdd]);

  const onAddResourcesClick = () => {
    if (resources.length) {
      setResourceModal(true);
    } else {
      toast.info('No resources available.');
    }
  };

  const onPDFClick = async (sourceSlug: string) => {
    try {
      setGeneratingPDF(true);
      const generatedPDF = await sendToWritePDF(b64(), sourceSlug);
      if (!generatedPDF)
        toast.error('There was an error creating the PDF file for the exam');
    } catch (error) {
      console.error("Error onPDFClick", error.message)
    }
    setGeneratingPDF(false);
  };

  return (
    <>
      <div className="content">
        <LoadingSpinner spinning={loading} tip={loadingTip}>
          <DetailLayout title={'Go back to Exam Drafts'}>
            <Row>
              {currentExam && questionListIsEmpty() && (
                <Col className="text-center" lg="4" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Questions</CardTitle>
                    </CardHeader>
                    <CardBody className="table-full-width table-hover exam-editor-question-table">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Topic</th>
                            <th className="text-right">Marks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentExam.examContent.content.map(
                            (e: ExamContentElement) => (
                              <tr
                                key={e.questionName}
                                onClick={(event) => onQuestionClick(event, e)}
                                className="question-row">
                                <td>{e.questionName}</td>
                                <td>{e.topicName}</td>
                                <td className="text-right">{e.marker}</td>
                                {/* 2024-06-24: Button is hidden because there is no way to restore a question if it was removed accidentally */}
                                {/* <td>
                                  <AntdButton
                                    className="btn-round btn-icon btn-icon-mini btn-neutral remove-question-button"
                                    color="danger"
                                    title="Remove From Exam">
                                    <i className="nc-icon nc-simple-remove" />
                                  </AntdButton>
                                </td> */}
                                <td>
                                  <AntdButton
                                    className="btn-round btn-icon btn-icon-mini btn-neutral shuffle-question-button"
                                    color="danger"
                                    title="Replace With Another Question">
                                    <i className="nc-icon nc-refresh-69" />
                                  </AntdButton>
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Resources&nbsp;&nbsp;
                        <AntdButton
                          onClick={() => onAddResourcesClick()}
                          className="btn-round btn-icon btn-icon-mini btn-neutral"
                          color="info"
                          title="Add Resources">
                          <i className="nc-icon nc-simple-add" />
                        </AntdButton>
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="table-full-width table-hover exam-editor-resource-table">
                      <Table responsive>
                        <tbody>
                          {currentExam.metadata.relatedDocuments &&
                            currentExam.metadata.relatedDocuments.map(
                              (resource: ExamResource) => (
                                <tr key={resource.id}>
                                  <td>{resource.name}</td>
                                  <td>
                                    <AntdButton
                                      onClick={() => {
                                        if (resource.resource) {
                                          downloadDocument(
                                            resource.resource?.fileHash,
                                            resource.resource?.displayName,
                                            currentUser.token,
                                          );
                                        }
                                      }}
                                      disabled={!resource.resource}
                                      className="btn-round btn-icon btn-icon-mini btn-neutral"
                                      title="Download Resource">
                                      <i className="nc-icon nc-cloud-download-93" />
                                    </AntdButton>
                                  </td>
                                  <td>
                                    <AntdButton
                                      onClick={() =>
                                        removeExamResourceFromDraft(resource)
                                      }
                                      className="btn-round btn-icon btn-icon-mini btn-neutral"
                                      title="Remove From Exam">
                                      <i className="nc-icon nc-simple-remove" />
                                    </AntdButton>
                                  </td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <Col lg={`${questionListIsEmpty() ? 8 : 12}`} md="12">
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Design Exam {customName}</h4>
                  </CardHeader>
                  <CardBody>
                    {currentDoc ? (
                      <div>
                        <div className="exam-editor-buttons">
                          {generatingPDF ? (
                            <LoadingSpinner spinning={true} />
                          ) : (
                            <Button
                              className="btn-round btn btn-danger"
                              onClick={() => onPDFClick(currentDoc.sourceSlug)}>
                              <i className="nc-icon nc-simple-remove" /> PDF
                            </Button>
                          )}
                          {currentExam && (
                            <Button
                              className="btn-round btn btn-danger"
                              onClick={() =>
                                downloadAnswers(
                                  currentExam.id!,
                                  `${customName}`,
                                )
                              }>
                              <i className="nc-icon nc-simple-remove" />
                              Grading Scheme
                            </Button>
                          )}
                          <Button
                            className="btn-round btn btn-info"
                            onClick={() => saveDraft()}>
                            <i className="nc-icon nc-simple-remove" />
                            Save
                          </Button>
                          {currentExam && (
                            <Button
                              className="btn-round btn btn-info"
                              onClick={() => setShuffleConfirm(true)}>
                              <i className="nc-icon nc-refresh-69" />
                              Shuffle
                            </Button>
                          )}
                        </div>
                        {shuffleConfirm && (
                          <ReactBSAlert
                            warning
                            style={{ display: 'block', marginTop: '-100px' }}
                            title={'Exam Shuffle'}
                            onConfirm={shuffleDraft}
                            onCancel={() => setShuffleConfirm(false)}
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="danger"
                            confirmBtnText={'Shuffle'}
                            cancelBtnText="Cancel"
                            showCancel
                            btnSize="">
                            Are you sure you want to shuffle all exam questions?
                          </ReactBSAlert>
                        )}
                        <div className="">
                          {examDoc.content && (
                            <TinyMCETextEditor
                              onChange={updateDoc}
                              autoresize={false}
                              value={examDoc.content}
                              getEditor={getEditor}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <p>nanai</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </DetailLayout>
          <Modal centered isOpen={resourceModal}>
            <ModalHeader>
              Add Resources
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={closeResourceModal}>
                <i className="nc-icon nc-simple-remove" />
              </button>
            </ModalHeader>
            <ModalBody>
              <Form className="form-horizontal">
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Select
                        isMulti
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="resources"
                        value={resourcesToAdd}
                        onChange={onResourceChange}
                        options={resources}
                        getOptionLabel={(item) => item.name || ''}
                        getOptionValue={(item) => item.id || ''}
                        placeholder="Choose question resources"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center" sm="12">
                    <Button
                      className="btn-round btn btn-info"
                      onClick={addResources}>
                      Save
                    </Button>
                    <Button
                      className="btn-round btn btn-danger"
                      onClick={closeResourceModal}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </LoadingSpinner>
      </div>
      <ToastContainer />
    </>
  );
};

export default ExamEditor;
