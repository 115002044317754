import ApiCaller from '../lib/ApiCaller';
import SyllabusCollection from '../lib/common/models/syllabusCollection';
import Syllabus from '../lib/common/models/syllabus';

class SyllabusApi {
    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    async getAllSyllabus(token?: string): Promise<SyllabusCollection | void> {
        try {
            const data = await this.apiCaller.call(
                '/v1/syllabus',
                'GET',
                undefined,
                token
            );
            const syllabusArray = data.syllabuses.map((syllabus: any) => new Syllabus(syllabus));
            const syllabusCollection = new SyllabusCollection(syllabusArray);
            return syllabusCollection;
        } catch (err) {
            console.error(err);
            alert('Error fetching syllabus');
        }
    }

    async create(code:string, title:string, token?:string): Promise<void  | Syllabus> {
        try {
            const data = await this.apiCaller.call('/v1/syllabus', 'POST', {
                code: code,
                title: title,
            }, token);
            var apiUser = new Syllabus(data);
            return apiUser;
        } catch (err) {
            console.error(err);
            alert('Error creating user, please try again.');
        }
    }

    async updateSyllabus(syllabus: Syllabus, token?: string): Promise<void | Syllabus> {
        try {
            const data = await this.apiCaller.call('/v1/syllabus/' + syllabus._id, 'PUT', {
                code: syllabus.code,
                title: syllabus.title,
            }, token);
            var apiSyllabus = new Syllabus(data);
            return apiSyllabus;
        } catch (err) {
            console.error(err);
            throw Error(err);
        }
    }


    async deleteSyllabus(syllabusId: string, token?: string): Promise<void> {
        try {
            return this.apiCaller.call('/v1/syllabus/' + syllabusId, 'DELETE', {
                id: syllabusId
            }, token);
        } catch (err) {
            console.error(err);
            alert("Error deleting syllabus, please try again");
        }
    }
}

export default SyllabusApi;
